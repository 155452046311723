@import "./resources/scss/index.scss";
@import "antd/dist/antd.css";
@import "./resources/scss/antd.scss";

body {
  margin: 0;
  font-family: MADE Tommy Soft !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --axia-blue: #1c8fe1;
  font-size: 14px;
}

code {
  font-family: MADE Tommy Soft !important;
}

// #root {
//   font-family: MADE Tommy Soft;
// }
