/*PROFILE*/
.profile-menu-wrapper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}

.profile-menu-bar {
  width: 100%;
  border-radius: 20px;
  background-color: white;
  padding: 10px;
  margin: 0 0 10px 0;
}

.profile-picture-title {
  display: none;
  padding: 20px;
  font-weight: 400px;
  font-size: 16px;
}

.profile-info-field {
  font-weight: 400px;
  font-size: 16;
  border: 2px solid #b1b5c4;
  border-radius: 12px;
  padding: 10px;
  width: 98%;
  min-height: 50px;
}

.profile-info-field-dynamic {
  font-weight: 400px;
  font-size: 16;
  border: 2px solid #b1b5c4;
  border-radius: 12px;
  padding: 6px;
  width: 99%;
  min-height: 50px;
}

.profile-info-part-one {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.change-password-input-field {
  border: 2px solid #b1b5c4;
  border-radius: 12px;
  min-height: 56px;
}

.profile-picture-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-address-input-row {
  display: block;
}

.profile-side-menu-btn-selected,
.profile-side-menu-btn {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 20px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  border: none;
  background-color: transparent;
  border-radius: 12px;
  margin: 2px;
  transition: 0.3s;
}

.row.flex-direction-to-column {
  flex-direction: column;
}

.profile-side-menu-btn:hover,
.profile-side-menu-btn-selected {
  background-color: #e9f6ff;
  color: $primary;
}

.profile-wrapper-page-area {
  width: 100%;
}

// MFA
.mfa-modal-container {
  width: auto !important;
  margin-bottom: 100px;
  .ant-modal-content {
    width: 90vw;
    max-width: 640px;
    min-width: 300px;
  }
}
.setup-totp-title {
  font-weight: 600;
  margin-bottom: 20px;
}
.setup-totp-subtitle {
  font-weight: 600;
}
.setup-totp-text-container {
  margin-top: 30px;
  text-align: center;
}

@media only screen and (min-width: $screen-md-min) {
  .profile-menu-wrapper-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 40px;
    margin: 0px;
    overflow: scroll;
  }

  .row.flex-direction-to-column {
    flex-direction: row;
  }

  .profile-info-part-one {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 360px;
  }

  .profile-menu-bar {
    width: 25%;
    min-width: 200px;
  }

  .profile-wrapper-page-area {
    margin-left: 20px;
  }

  .change-password-input-field {
    max-width: 400px;
  }

  .profile-picture-title {
    display: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .profile-picture-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    width: 50%;
    align-items: center;
  }

  .profile-address-input-row {
    display: flex;
    flex-direction: row;
  }
}
