@import "./variables.scss";

.step-body {
  align-items: center;
  place-self: center;
}

.card-holder-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardDetail-overall-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cardDetail-overall-container {
  display: flex;
  flex-direction: column;
  place-self: center;
}
.cardDetail-cardStatus {
  margin-top: 20px;
  margin-right: 20px;
}
.cardDetail-balance {
  text-align: left;
  font-size: 15px;
  margin-top: 30px;
  margin-left: 30px;
}
.cardDetail-cardImage {
  img {
    width: 100%;
    height: 100%;
  }
  margin: 10px;
  position: relative;
}
.cardDetail-expireCvv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  color: grey;
}
.cardDetail-cardInfoSection {
  margin: 10px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.address-table {
  td {
    height: 34px;
    text-align: left;
    padding-right: 30px;
  }
}
.card-status {
  p {
    margin: 0px;
    font-weight: 500;
    font-size: 18px;
  }
  padding: 2px 10px;
  border-radius: 20px;
  background-color: transparent;
}

.card-status-ACTIVE {
  border: 2px solid #35b994;
  color: white;
  background-color: #35b994;
}
.card-status-SUSPENDED {
  border: 2px solid red;
  color: red;
}

.card-status-INACTIVE {
  border: 2px solid #efd915;
  color: #efd915;
}
.card-status-LOST,
.card-status-STOLEN {
  border: 2px solid red;
  color: red;
}
.card-status-UNKNOWN,
.card-status-EXPIRED {
  border: 2px solid #cccccc;
  color: #cccccc;
}
.load-btn {
  border-radius: 10px;
  background-color: #35b994;
  color: #ffffff;
  font-weight: 500;
  justify-self: flex-end;
  margin-right: 10px;
}
.cardDetail-detail-container {
  display: flex;
  flex-direction: column;
}
.tab-rows {
  p {
    margin: 0px;
  }
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #f5f0f0;
  margin: 0px 20px;
  text-align: left;
}
.popup-modal-container {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 20px;
  top: 5%;
  left: 5%;
  right: 5%;
}
.no-card-container {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 3% 0%;
  width: 80%;
  min-width: 400px;
}
.card-list-container {
  display: inline-block;
  // min-width: 350px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  margin: 20px;
  background-image: url("../images/card1.png");
  background-size: cover;
}
.card-list-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-items: start;
}
.card-transaction-container {
  background: #ffffff;
  border-radius: 20px;
  margin: 0px 30px;
}
.card-transaction-date-picker {
  display: flex;
  flex-direction: row;
  width: 150px;
  margin: 0px 30px 10px auto;
  border-radius: 10px;
}
.card-apply-container {
  .title,
  .title > P {
    margin: 10px 0;
    font-size: 12px;
    color: #a8adad;
    text-align: left;
  }
  display: flex;
  flex-direction: column;
  width: 85%;
  background: #ffffff;
  border-radius: 20px;
  padding: 30px 10px;
  margin-bottom: 60px;
  min-width: 300px;
}
.card-apply-container .ant-select-selector,
.card-apply-container .ant-input {
  border-radius: 12px !important;
  border: 2px solid #b1b5c4 !important;
  background-color: white !important;
}
.card-apply-container .title input,
.card-apply-container .title .ant-select-selector,
.card-types-dropdown-selector .ant-select-selector {
  // min-height: 46px;
  padding: 10px 20px !important;
  height: unset !important;
}
.billing-address-box {
  p {
    margin: 0px;
    font-size: 16px;
  }
  text-align: left;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid #b1b5c4;
}
.separator {
  width: 1px;
  background: rgb(240, 229, 229);
  left: 50%;
  margin: 0 40px;
}

@media only screen and (min-width: $screen-sm-min) {
  .card-container {
    padding: 20px;
  }

  .tab-rows {
    flex-direction: row;
  }

  .popup-modal-container {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 20px;
    // height: 580px;
    width: 50%;
    top: 10%;
    left: 25%;
  }
  .card-container {
    padding: 30px;
  }
  .no-card-container {
    background-color: #ffffff;
    border-radius: 20px;
    max-width: 520px;
    min-width: 400px;
    width: 80%;
    height: 409px;
    display: inline-block;
  }
}

@media only screen and (min-width: $screen-md-min) {
  .cardDetail-overall-row {
    min-width: 700px;
  }

  .cardDetail-overall-container {
    display: flex;
    flex-direction: column;
    place-self: center;
  }

  .card-apply-container {
    min-width: unset;
  }
}

@media only screen and (min-width: 1100px) {
  .cardDetail-overall-container {
    display: flex;
    flex-direction: row;
    place-self: center;
  }

  .step-body {
    place-self: center;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0 10px;
    align-items: center;
  }
}
