.ant-spin {
  max-height: unset !important;
}

.form-terms-group {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.sign-up-confirm__container {
  .ant-statistic {
    display: inline-block;
    width: fit-content;
    .ant-statistic-content {
      font-size: unset !important;
    }
  }
}
