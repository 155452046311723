@import "./variables.scss";
@import "./colors.scss";

/*LAYOUT*/
.site-layout-header {
  background-color: white;
}

.ant-layout-header {
  background-color: white !important;
  padding: 0 !important;
  height: unset !important;
}

.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header-status-icons {
  display: flex;
  align-items: center;
  align-self: start;
  width: 100%;
  justify-content: space-between;
}

.header-list-name {
  display: flex;
  align-items: center;
  align-self: start;
  padding-left: 10px;
}

.header-main {
  display: flex;
  flex-direction: column;
  height: auto;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.header-service-status-tag {
  display: flex;
  align-items: end;
  font-size: 1rem;
  width: 100%;
}

.header-service-status-outer-tag {
  width: 100%;
  font-size: 12px;
  padding: 0.25rem;
  margin-left: 5px;
  margin-right: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.header-title {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.header-notification-container {
  background-color: #35b994 !important;
  padding: 10px 20px;
}

.header-notification-text {
  color: white;
  padding: 0;
  margin: 0;
  font-size: 22px;
  margin: 0 20px;

  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 1.2em;
  // max-height: 3.6em;
  // display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex: 1;
}

.banner-header > * {
  color: white;
  margin: 0;
}

.banner-body > * {
  color: white;
  margin: 0;
}

/*MENU BAR*/
.ant-menu-item:hover,
.ant-menu-item-selected {
  img {
    filter: brightness(2);
  }
  border-radius: 12px;
  background-color: $primary !important;
}

.menubar-logo {
  display: flex;
  justify-content: center;
  padding: 18px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  background-color: #007cbd;
}

.menu-sider {
  min-width: 300px !important;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  background-color: #007cbd;
}

.menu-sider-display {
  display: none;
}

.adm-tab-bar {
  display: flex !important;
}

.site-layout {
  margin-left: 0;
}

@media only screen and (min-width: $screen-md-min) {
  .menu-sider-display {
    display: unset;
  }

  .header-status-icons {
    justify-content: end;
  }

  .menu-drawer-btn {
    display: none;
  }

  .header-service-status-outer-tag {
    font-size: x-small;
  }

  .header-main {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  }

  .adm-tab-bar {
    display: none !important;
  }

  .site-layout {
    margin-left: 300px;
  }
}

@media only screen and (min-width: $screen-lg-min) {
  .header-service-status-tag {
    margin-top: 5px;
    display: "flex";
    justify-content: "end";
    width: auto;
  }
  .header-service-status-outer-tag {
    width: auto;
    margin-right: 5px;
    border-radius: 8px;
    font-size: 12px;
  }
  .header-main {
    flex-direction: row;
  }
  .header-list-name {
    width: -webkit-fill-available;
  }
  .header-status-icons {
    margin-right: 10px;
  }
}

@media only screen and (min-width: $screen-xl-min) {
  .icon-megaphone {
    margin-left: 10px;
  }
  .header-service-status-outer-tag {
    font-size: small;
  }
  .ant-dropdown-link {
    margin-left: 10px;
    margin-right: 10px;
  }
}
