@import "./variables.scss";

/* LOGIN PAGE */
.auth-content__container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1.5rem;
  width: 100%;
  margin: auto;
  max-width: 600px;
  min-width: 300px;
}

.auth-page__large-title {
  font-size: 25px;
  font-weight: 500;
}
.auth-page__title {
  font-size: 24px;
  font-weight: 500;
}

.auth-page__subtitle {
  font-size: 18px;
  font-weight: 500;
  color: dimgray;
  margin-bottom: 10px;
}

.login-page__forgot-password {
  margin: 40px 0 5px;
  text-align: right;
}

.acb-primary-input {
  padding: 10px;
  border-radius: 5px;
  input,
  input:-webkit-autofill::first-line {
    font-size: 16px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px white inset;
  }
}

// SIGN UP CONFIRM
.form-terms-group {
  a {
    color: black;
    font-weight: 600;
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}
/* AUTH WRAPPER */
.auth-wrapper__container {
  min-height: 100vh;
}
.logo-image {
  height: 50px;
  margin-bottom: 50px;
  display: block;
}
.logo-text {
  font-size: 40px;
  font-weight: 700;
  color: white;
  padding-left: 30px;
  white-space: nowrap;
  overflow: hidden;
}

// MFA LOGIN
.confirm-login-container {
  min-height: 100vh;
  display: flex;
  padding: 0 2rem;
}
.confirm-login__logout-button {
  position: absolute;
  right: 2.5rem;
  top: 1rem;
  font-weight: 800;
  color: white;
  background-color: #000000;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  padding: 5px 12px;
}
.confirm-login-content {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 2.25rem;
    font-weight: 700;
  }
  p {
    margin: 0 auto 4rem;
    font-size: 1rem;
  }
  .ant-spin-nested-loading {
    width: 100%;
  }
}
.confirm-login__form {
  width: 100%;
  margin-top: 1rem;
  max-width: 512px;
  * {
    width: 100%;
  }
  .acb-button-primary {
    height: 3rem;
    margin-top: 30px;
    font-size: 1.2rem;
  }
}

.confirm-login__error-container {
  width: 100%;
  padding: 20px;
  background: #faeceb;
  border-radius: 2px;
  font-size: 15px;
  color: #ef2424;
  span {
    color: #ef2424;
    margin-right: 10px;
  }
}

@media only screen and (min-width: $screen-md-min) {
  // AUTH WRAPPER
  .auth-page__large-title {
    font-size: 30px;
  }

  .auth-page__subtitle {
    font-size: 24px;
  }
  .auth-page__message {
    font-size: 16px;
  }
}
