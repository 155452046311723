@import "./variables.scss";

.gift-card-step-body {
  max-width: 800px;
  width: 90%;
}

.gift-card-brand-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}

.gift-card-brand-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  img {
    max-width: 100%;
    object-fit: contain;
  }
}

.gift-card-brand-card {
  border-radius: 1rem;
  padding: 1rem;
  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.28) 2px 2px 10px;
  }
}

.gift-card-button-row {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 2rem;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-end;
}

@media only screen and (min-width: $screen-md-min) {
  .gift-card-brand-list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }
}

@media only screen and (min-width: 1100px) {
  .gift-card-step-body {
    max-width: 900px;
  }

  .gift-card-brand-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .gift-card-brand-info {
    flex-direction: row;
    // margin: auto;
    img {
      max-width: 50%;
    }
  }

  .gift-card-button-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (min-width: 2000px) {
  .gift-card-step-body {
    max-width: 1500px;
  }

  .gift-card-brand-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
