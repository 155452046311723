@import "./variables.scss";
@import "./colors.scss";

/*COMMON*/
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
}

.back-btn {
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: $primary;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: center;
}

.back-btn:hover {
  background-color: transparent;
  color: #167ec4;
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row.account-head-text,
.row.page-title {
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row-justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.grey-horizontal-line {
  height: 1px;
  background: lightgray;
  flex: 1;
}

.clickable-text {
  font-weight: 500;
  color: $primary;
  cursor: pointer;
}

.disabled-clickable-text {
  font-weight: 500;
  cursor: no-drop;
}

.align-items-center {
  align-items: center;
}

.hide {
  display: none;
}

.padding-none {
  padding: 0;
}

.margin-none {
  margin: 0;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 20px 40px;
}

.body-padding {
  padding: 20px 40px;
}

.avoid-bottom-bar {
  margin-bottom: 60px;
}

.adjustable-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.sticky-top {
  position: sticky;
  top: 0;
  // z-index: 9999;
}

.simple-button {
  background-color: #3b82f6;
  padding: 5px 20px;
  border-radius: 5px;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.simple-button:hover {
  cursor: pointer;
  background-color: #3a82f6;
}

.display-mobile-unset {
  display: none;
}

.overflow-scroll {
  overflow: scroll;
}

.cursor-pointer {
  cursor: pointer;
}

.breank-long-string {
  width: 100%;
  word-wrap: break-word;
  display: inline-block;
  text-align: left;
  white-space: normal;
}

.key-value-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
  top: 45% !important;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
/* For all pages */
.all-page-container {
  height: 100%;
  overflow: scroll;
}

// antd elements apply
.ant-spin {
  font-size: 20px;
  font-weight:400
}

.ant-modal-content,
.ant-select {
  border-radius: 20px;
}
.ant-modal-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.ant-select-selector,
.ant-btn,
.ant-input-number,
.ant-input-number-handler-wrap,
.ant-input {
  border-radius: 10px !important;
}

.ant-spin-nested-loading {
  width: 100%;
}
.ant-notification-notice-description {
  color: #a5a5a5;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  // width: 0; /* Remove scrollbar space */
  // background: transparent; /* Optional: just make scrollbar invisible */
}
::-webkit-scrollbar-track {
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #1670ac70;
  border-radius: 20px;
}

/*AMOUNT INPUT*/
.amount-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.amount-input-section {
  width: 100%;
  margin: 0 0 10px 0;
  font-size: 30px;
  font-weight: normal;
  padding-left: 1.5rem;
  border: 2px solid #e6e8ec;
  border-radius: 12px;
}
.amount-account-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-radius: 12px;
  border: 2px solid #e6e8ec;
  margin: 0;
  padding: 5px;
  align-items: center;
  background-color: #f8f9fa;
}

// Fee Row
.fee-row-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
}
.fee-row-margin {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 5px 0px;
}

/*CARD BUTTON*/
.card-button-container {
  img {
    width: 80%;
  }
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: $primary;
}

.card-button-container:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.28) 2px 2px 10px;
}

.card-button-text {
  font-size: 20px;
  font-weight: 400;
}

/*LIST CARD*/
.list-card {
  position: relative;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 1rem 1.5rem 1rem;
  cursor: pointer;
  box-shadow: 0px 4px 16px rgba(20, 92, 143, 0.08);
  border-radius: 12px;
  margin-bottom: 5px;
}
.account-list-item-flex {
  flex-direction: column;
  margin-right: 0px;
}

/*STEP MENU*/
.reached-step {
  height: 30px;
  width: 4px;
  background-color: #35b994;
  margin-left: 10px;
  border-radius: 20px;
}

.unreached-step {
  height: 30px;
  width: 4px;
  background-color: $disable;
  margin-left: 10px;
  border-radius: 4px;
}

.step-grid {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
}

.step-grid > div {
  display: flex;
  height: 30px;
  align-items: center;
  margin-bottom: 20px;
}

.step-grid p {
  margin-left: 10px;
  align-self: center;
}

// BUTTON PRIMARY
.acb-button-primary {
  font-weight: 600;
  color: white;
  border-radius: 0.375rem;
  background-color: $primary;
  margin: 20px auto 50px;
  :hover {
    cursor: pointer;
  }
}

.mobile-sidebar-close-btn {
  border: none;
  border-radius: 25px !important;
  height: 50px;
  width: 50px;
}

/*Error Page*/
.error-page-container {
  img {
    max-width: 300px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
}

/*WireTransfer*/
.section-title {
  display: flex;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-weight: 600;
}
.banking-details {
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  overflow: hidden;
  .box {
    padding: 20px;
    border: 2px solid #dddddd;
    box-sizing: border-box;
    border-radius: 12px;
  }
}

.wire-info > * {
  margin: 0;
}

/*Digital Currency Deposit*/
.signature-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.beneficiary-content-container {
  p {
    color: #b1b5c3;
    font-size: 13px;
    margin: 5px 0;
  }
  display: flex;
  flex-direction: column;
  gap: 0px;
  .content-box {
    flex: 1;
    width: 100%;
  }
}
.WTContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

// REACT CODE INPUT
.react-code-input {
  display: flex !important;
  justify-content: space-between;
  input {
    text-align: center;
    padding: 0 !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: textfield; /* Firefox */
  }
}

// Beneficiaries
.BContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.on-cursor-hover-blue:hover {
  span {
    color: white !important;
  }
  cursor: pointer;
  background-color: #35b994;
  color: white;

  .lightgrey-color {
    color: white;
  }

  .green-color {
    color: white;
  }

  .red-color {
    color: white;
  }
}

.lightgrey-color {
  color: #a3a3a3;
}

.green-color {
  color: #53e085;
}

.red-color {
  color: red;
}

@media only screen and (min-width: $screen-md-min) {
  // .grid-1 {
  //   display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  //   gap: 20px;
  //   grid-auto-rows: minmax(100px, auto);
  // }

  /* For pdf */
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    top: 50% !important;
  }

  .header-notification-container {
    padding: 10px 50px;
  }

  /*STEP MENU*/
  .reached-step {
    height: 12%;
    width: 42%;
    background-color: #35b994;
    margin-bottom: 10px;
    border-radius: 20px;
    max-width: 135px;
  }

  .unreached-step {
    height: 12%;
    width: 42%;
    background-color: $disable;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .step-grid {
    display: flex;
    flex-direction: row;
    height: 30px;
  }
  .step-grid > div {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    height: 45px;
  }

  .avoid-bottom-bar {
    margin-bottom: unset;
  }

  .display-mobile-unset {
    display: flex;
  }

  .adjustable-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .account-list-item-flex {
    flex-direction: row;
    margin-right: 30px;
  }

  .cards-part2,
  .gift-card-part2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
  }

  /*Digital Currency Deposit*/
  .signature-container {
    display: flex;
    flex-direction: row;
  }

  /*AMOUNT INPUT*/
  .amount-input-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .amount-input-section {
    width: 50%;
    margin: 0 2px 0 0;
    padding-left: 1.5rem;
    font-size: 30px;
    font-weight: normal;
    border: 2px solid #e6e8ec;
    border-radius: 12px;
  }
  .amount-account-section {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-radius: 12px;
    border: 2px solid #e6e8ec;
    margin-left: 2px;
    padding: 5px;
    align-items: center;
    background-color: #f8f9fa;
  }

  // Fee Row
  .fee-row-margin {
    margin: 5px 150px 5px 0px;
  }

  // Beneficiaries
  .BContainer {
    padding: 3rem;
  }

  // REACT CODE INPUT
  .react-code-input {
    input {
      width: 50px !important;
      height: 50px !important;
    }
  }

  // Wire Transfer
  .WTContainer {
    display: flex;
    flex-direction: column;
    padding: 3rem;
  }

  /*Error Page*/
  .error-page-container {
    img {
      max-width: 500px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  // .key-value-2 {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  // }

  .breank-long-string {
    white-space: nowrap;
  }
}

@media only screen and (min-width: $screen-lg-min) {
  .grid-1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }
  .key-value-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media only screen and (min-width: $screen-xl-min) {
  .grid-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }
  .beneficiary-content-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .content-box {
      flex: 1;
      width: 100%;
    }
  }
}

@media (max-width: $screen-md-min) {
  @supports (-webkit-appearance: none) {
    .react-code-input {
      input {
        height: 44px !important;
      }
    }
  }
}
