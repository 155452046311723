@import "./variables.scss";

.stakings__product-row {
  white-space: nowrap;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  overflow-X: scroll
}

.stakings__product-row::-webkit-scrollbar {
  display: none;
}

.stakings__product-item {
  width: 300px;
  min-width: 300px;
  background-color: white;
  border-radius: 20px;
  // padding: 20px 20px;
  margin: 10px 10px 10px 10px;
}

.stakings__product-item-header {
  background: linear-gradient(51.63deg, #35B994 22.09%, #66CFB2 77.91%);
  padding: 10px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 26px;
  font-weight: 600;
  color: white;
}

.stakings__product-item-body {
  height: 80px;
  max-height: 80px;
  padding: 10px 20px;
}

.stakings__product-item:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.28) 2px 2px 10px;
}

.stakings__product-item-name {
  text-align: center;
  white-space: initial;
  font-size: 15px;
  font-weight: 400;
}

.stakings__product-item-room-limit {
  white-space: nowrap;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
}

.stakings__product-item-short-desc {
  white-space: initial;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: darkgrey;
}

.stakings__user-staking-item {
  justify-content: space-between;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  transition: 0.3s;
  padding: 20px;
  align-items: center;
}
.stakings__user-staking-item:hover {
  span {
    color: white !important;
  }
  cursor: pointer;
  background-color: #35B994;
  color: white;
}

.stakings__product-modal-header {
  background: linear-gradient(51.63deg, #35B994 22.09%, #66CFB2 77.91%);
  padding: 10px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.stakings__product-modal-header h1 {
  color: white;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 10px;
}

.stakings__product-modal-header p {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

@media only screen and (min-width: $screen-md-min) {
  .stakings__user-staking-item {
    flex-direction: row;
  }
}
