@import "./variables.scss";
@import "./colors.scss";

.onboarding__layout-head {
  background-color: $primary;
  margin-bottom: 20px;
}

.onboarding__layout-logo-area {
  height: 160px;
}

.onboarding__layout-logo {
  width: 500px;
  justify-content: center;
}
