@import "./variables.scss";

.between-account.step-body {
  max-width: 600px;
  width: 90%;
}

.add-beneficiary-modal {
  padding-bottom: 100px;
}

@media only screen and (min-width: $screen-md-min) {
  .between-account.step-body {
    max-width: 600px;
  }
  .add-beneficiary-modal,
  .add-beneficiary-modal .ant-modal-content {
    width: 900px !important;
    padding-bottom: 0px
  }
}

@media only screen and (min-width: $screen-lg-min) {
}

@media only screen and (min-width: $screen-xl-min) {
}
