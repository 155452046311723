@import "./variables.scss";

/*ACCOUNTS HEAD*/
.account-head-container {
  background-color: $secondary;
  padding: 20px 30px;
}

.account-head-text {
  font-size: 28px;
  color: white;
  font-weight: 500;
}

.account-head-cards-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.account-head-card-container {
  background-color: white;
  padding: 20px 20px;
  border-radius: 20px;
  min-height: 150px;
  min-width: 280px;
  color: $primary;
  margin: 10px;
}

/*ACCOUNT MID*/
.account-section-container {
  background: #ffffff;
  border-radius: 20px;
  margin: 20px 40px;
}

.account-mid-axia-wallet-card {
  p.account-mid-wallet-card-name {
    font-size: 15px;
    font-weight: 500;
    margin: 0 5px;
    white-space: nowrap;
  }
  p.account-mid-wallet-card-amount {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
  background-color: white;
  padding: 20px 10px;
  box-shadow: 0px 4px 16px rgba(20, 92, 143, 0.08);
  border-radius: 20px;
  // margin-top: 10px;
  width: 100%;
  height: 130px;
  margin: 5px 10px;
}

.account-mid-axia-wallet-card:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.28) 2px 2px 10px;
}

.accounts-mid-buttons-row {
  span {
    text-shadow: none;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 200px;
  border: 1px solid $primary;
  border-radius: 16px;
  padding: 5px;
}

.accounts-mid-cards-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 35px;
  padding-bottom: 10px;
}

/*ACCOUNT TRANSACTION*/
.accounts-transaction-box {
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 0 40px;
}

.accounts-transaction-item-container {
  border-bottom: 1px solid lightgrey;
  display: block;
  flex-direction: row;
  padding: 10px 0;
  cursor: pointer;
}

.accounts-transaction-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  text-align: left;
  flex-direction: column;
}

.account-detail-back-to-overview-text {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.account-detail-back-to-overview-text:hover {
  text-decoration: underline;
}

.account-detail-account-alias-text {
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  flex: 1;
  text-align: left;
  display: flex;
  justify-content: center;
}

.account-detail-account-balance-card {
  background-color: white;
  padding: 20px 20px;
  border-radius: 20px;
  margin: 5px;
  width: 100%;
  height: 130px;
}

.account-detail-account-detail-card {
  background-color: white;
  padding: 20px 20px;
  border-radius: 20px;
  margin: 5px;
  width: 100%;
  height: 150px;
}

.account-transaction-item-modal-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  // grid-auto-rows: minmax(100px, auto);
}

.account-transaction-item-modal-bottom-line {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 20px;
}

.transaction-gateway-body {
  width: 100%;
  margin-bottom: 50px;
}
.transaction-gateway-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (min-width: $screen-md-min) {
  .account-head-cards-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }

  .account-mid-axia-wallet-card {
    p.account-mid-wallet-card-name {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
      margin-left: 10px;
    }
    p.account-mid-wallet-card-amount {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
    }
    background-color: white;
    padding: 20px 20px;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
    width: 400px;
    min-width: 400px;
    height: 130px;
  }

  .accounts-transaction-bottom {
    flex-direction: row;
  }

  .accounts-mid-cards-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: scroll;
    padding-bottom: 10px;
  }

  .accounts-transaction-box {
    background-color: white;
    border-radius: 20px;
    margin: 0 40px;
    padding: 10px 20px;
    width: unset;
  }

  .accounts-transaction-item-container {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
  }

  .account-detail-account-balance-card {
    width: 100%;
    min-width: 400px;
  }

  .account-detail-account-detail-card {
    background-color: white;
    padding: 20px 20px;
    border-radius: 20px;
    height: 130px;
    min-width: 400px;
  }

  .account-transaction-item-modal-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    // grid-auto-rows: minmax(100px, auto);
  }

  .account-transaction-item-modal-bottom-line {
    border-bottom: none;
    padding-bottom: unset;
  }
}

@media only screen and (min-width: $screen-lg-min) {
  .account-head-cards-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
  }
  .transaction-gateway-body {
    max-width: 850;
    min-width: 600px;
    width: unset;
  }
  .transaction-gateway-title {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
}

@media only screen and (min-width: $screen-xl-min) {
  .account-head-cards-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    overflow: auto;
  }
}
